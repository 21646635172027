<template>
  <div v-if="channel">
    <v-container grid-list-lg fluid p-0>
      <v-layout wrap row>
        <v-flex xs12 sm12>
          <v-card>
            <!--<v-card-title>-->
            <!--<span class="headline">-->
            <!--{{ influencer.user.first_name + ' ' + influencer.user.last_name }}-->
            <!--</span>-->
            <!--</v-card-title>-->
            <v-card-text>
              <v-layout wrap row>
                <v-flex xs12 sm3 class="text-center">
                  <channel-profile-avatar
                    :user="user"
                    :channel="channel"
                  ></channel-profile-avatar>
                </v-flex>
                <v-flex xs12 sm9>
                  <v-layout wrap row>
                    <v-flex xs12 sm3>
                      <v-list two-line dense>
                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Name</v-list-item-title>
                            <v-list-item-subtitle>{{
                              user.first_name + " " + user.last_name
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title
                              >{{ channel.channel.display_name | first_letter
                              }}{{
                                channel.channel.display_name.slice(1)
                              }}</v-list-item-title
                            >
                            <v-list-item-subtitle>{{
                              channel.username
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Age</v-list-item-title>
                            <v-list-item-subtitle>{{
                              user.influencer.birthday | date_to_age
                            }}</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>

                        <v-list-item>
                          <v-list-item-content>
                            <v-list-item-title>Gender</v-list-item-title>
                            <v-list-item-subtitle
                              v-text="
                                user.influencer.gender === 'MALE'
                                  ? 'Male'
                                  : 'Female'
                              "
                            ></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                    </v-flex>
                    <v-flex xs12 sm9>
                      <h3 class="subheading">Categories</h3>

                      <v-layout row wrap>
                        <v-flex
                          v-for="category in channel.categories"
                          :key="category.id"
                          xs12
                          sm6
                          md4
                        >
                          <v-chip
                            outlined
                            style="width:100%;"
                            disabled
                            color="primary"
                          >
                            <v-avatar>
                              <v-icon class="ml-2">{{ category.icon }}</v-icon>
                            </v-avatar>

                            {{ category.display_name }}
                          </v-chip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-card-text>
          </v-card>
        </v-flex>

        <template v-if="stats">
          <v-flex md4 sm6 xs12>
            <stat-widget
              :icon="channel.channel.icon"
              :title="channel.followers | local_numbers"
              subtitle="Followers"
            ></stat-widget>
          </v-flex>
          <v-flex md4 sm6 xs12>
            <stat-widget
              icon="fal fa-images"
              :title="channel.media_count | local_numbers"
              subtitle="Feed posts"
            ></stat-widget>
          </v-flex>
          <v-flex md4 sm12 xs12>
            <stat-widget
              icon="fal fa-eye"
              :title="profileViews | friendly_numbers"
              subtitle="Profile views"
            ></stat-widget>
          </v-flex>
          <v-flex sm6 xs12>
            <stat-widget
               icon="fal fa-bullseye"
               :title="reach | friendly_numbers"
               subtitle="Reach"
            ></stat-widget>
          </v-flex>
          <v-flex sm6 xs12>
            <stat-widget
               icon="fal fa-eye"
               :title="impressions | friendly_numbers"
               subtitle="Impressions"
            ></stat-widget>
          </v-flex>

          <v-flex xs12 sm6>
            <v-card class="pie-chart">
              <v-card-title>
                <div>
                  <div class="headline">Follower countries</div>
                  <span class="subtitle-1 light-grey"
                    >Top 5 follower countries</span
                  >
                </div>
              </v-card-title>
              <v-card-text class="d-flex align-center">
                <influencer-channel-follower-country-chart
                  :value="stats.countries.slice(0, 5)"
                ></influencer-channel-follower-country-chart>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12 sm6>
            <v-card class="pie-chart">
              <v-card-title>
                <div>
                  <div class="headline">Follower cities</div>
                  <span class="subtitle-1 light-grey"
                    >Top 5 follower cities</span
                  >
                </div>
              </v-card-title>
              <v-card-text class="d-flex align-center">
                <influencer-channel-follower-city-chart
                  :value="stats.cities.slice(0, 5)"
                ></influencer-channel-follower-city-chart>
              </v-card-text>
            </v-card>
          </v-flex>

          <v-flex xs12>
            <v-card>
              <v-card-title>
                <div>
                  <div class="headline">Daily follower growth</div>
                  <span class="subtitle-1 light-grey"></span>
                </div>
                <v-spacer></v-spacer>
                <!--<v-select-->
                <!--:items="dateGroups"-->
                <!--v-model="dateGroup"-->
                <!--label="Gruppér pr."-->
                <!--class="mr-2 flex-auto"-->
                <!--sm3></v-select>-->
                <!--<date-range-picker v-model="dateRange"></date-range-picker>-->
              </v-card-title>
              <v-card-text>
                <follower-growth-chart
                  min-height="300px"
                  :data="followerGrowthData"
                ></follower-growth-chart>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm12 md6 lg4>
            <v-card class="pie-chart">
              <v-card-title>
                <div>
                  <div class="headline">Follower gender distribution</div>
                  <span class="subtitle-1 light-grey"
                    >Gender distribution of followers</span
                  >
                </div>
              </v-card-title>
              <v-card-text class="d-flex align-center">
                <influencer-channel-follower-gender-chart
                  :value="stats.gender_age"
                ></influencer-channel-follower-gender-chart>
              </v-card-text>
            </v-card>
          </v-flex>
          <v-flex sm12 md6 lg8>
            <v-card class="pie-chart">
              <v-card-title>
                <div>
                  <div class="headline">Follower age- and gender distribution</div>
                  <span class="subtitle-1 light-grey"></span>
                </div>
              </v-card-title>
              <v-card-text class="d-flex align-center">
                <influencer-channel-follower-gender-age-chart
                  :value="stats.gender_age"
                ></influencer-channel-follower-gender-age-chart>
              </v-card-text>
            </v-card>
          </v-flex>
        </template>
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import FollowerGrowthChart from "../../charts/FollowerGrowthChart";
import channel_color from "@/helpers/filters/channel_color";
import date_to_age from "@/helpers/filters/date_to_age";
import first_letter from "@/helpers/filters/first_letter";
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import local_numbers from "@/helpers/filters/local_numbers";
import StatWidget from "../../widgets/StatWidget";
import InfluencerChannelFollowerCountryChart from "../../charts/InfluencerChannelFollowerCountryChart";
import InfluencerChannelFollowerGenderChart from "../../charts/InfluencerChannelFollowerGenderChart";
import InfluencerChannelFollowerGenderAgeChart from "../../charts/InfluencerChannelFollowerGenderAgeChart";
import InfluencerChannelFollowerCityChart from "../../charts/InfluencerChannelFollowerCityChart";
import { mapState, mapActions } from "vuex";
import ChannelProfileAvatar from "../../avatars/InfluencerChannelAvatar";

export default {
  components: {
    ChannelProfileAvatar,
    InfluencerChannelFollowerCityChart,
    InfluencerChannelFollowerGenderAgeChart,
    InfluencerChannelFollowerGenderChart,
    InfluencerChannelFollowerCountryChart,
    StatWidget,
    FollowerGrowthChart
  },
  filters: {
    channel_color,
    date_to_age,
    first_letter,
    friendly_numbers,
    local_numbers
  },
  data: () => ({
    fab: false,
    channel: null,
    stats: null,
    followerCities: [],
    followerCountries: [],
    followerGenderAge: [],
    dateRange: null,
    menuProps: {
      maxWidth: "100px"
    },
    dateGroup: "week",
    dateGroups: [
      {
        name: "Day",
        value: "day"
      },
      "Week",
      "Month",
      "Year"
    ]
  }),
  computed: {
    ...mapState("core/auth", ["user"]),
    avatarSize() {
      if (this.$vuetify.breakpoint.sm) {
        return 150;
      } else if (this.$vuetify.breakpoint.xs) {
        return 100;
      }

      return 200;
    },
    followers() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.followers,
        0
      );
    },
    impressions() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.impressions,
        0
      );
    },
    reach() {
      return this.stats.daily.reduce(
         (accumulator, entry) => accumulator + entry.unique_profile_views,
         0
      );
    },
    profileViews() {
      return this.stats.daily.reduce(
        (accumulator, entry) => accumulator + entry.profile_views,
        0
      );
    },
    followerGrowthData() {
      let stats = this.stats.daily.slice(0, 14);
      let data = {
        dates: [],
        values: []
      };

      stats.forEach(value => {
        data.dates.push(value.date);
        data.values.push(value.followers);
      });

      data.dates.reverse();
      data.values.reverse();
      return data;
    }
  },
  watch: {
    channel: function() {
      this.fetchStats();
    }
  },
  methods: {
    ...mapActions("influencer", ["getInfluencerChannels", "getSingleChannel"]),
    ...mapActions("core", ["getInfluencerStats"]),

    fetchStats() {
      this.getInfluencerStats({
        influencer_channel_uuid: this.$route.params.id
      }).then(response => {
        this.stats = response;
      });
    }
  },
  created() {
    let id = this.$route.params.id;
    this.dateRange = {
      startDate: this.$moment()
        .subtract(30, "day")
        .format("YYYY-MM-DD"),
      endDate: this.$moment().format("YYYY-MM-DD")
    };
    this.getSingleChannel({ uuid: id }).then(data => {
      this.channel = data;
    });
  }
};
</script>

<style>
.v-card .echarts {
  width: 100%;
  height: auto;
  min-height: 250px;
}

.v-card.pie-chart {
  height: 100%;
}

.flex-auto {
  flex: initial;
}
</style>
